import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { css } from 'styled-components'
import { sectionThemeStyle, text } from '../../utils/styles'
import { breakpoints, colors, fontSizes, lineHeights, mediaQueries, space } from '../../utils/tokens'
import Container from '../Container'
import { TitleLineBreak } from '../TitleLineBreak'

export const CardCollectionSection = ({ section }) => {
  if(!section) {
    return null
  }

  const { title, subtitle, cards, body, type, theme = 'light' } = section

  console.log({ title, type  })

  return (
    <section
      css={css({
        color: colors.base,
        ...sectionThemeStyle[theme],
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px 0`,
        },
      })}
    >
      <Container>
        <div>
          {title && (
            <>
              <h2
                css={css({
                  ...text.sectionHeading,
                  textAlign: `center`,
                })}
              >
                {title}
              </h2>
              <TitleLineBreak center />
            </>
          )}
          {subtitle && (
            <div
              css={css({
                maxWidth: breakpoints.md,
                margin: `0 auto`,
              })}
            >
              <p css={css({ ...text.sectionSubHeading })}>
                {subtitle.internal?.content || subtitle}
              </p>
            </div>
          )}
        </div>
        <CollectionSelector cards={cards} type={type} theme={theme} />
      </Container>
    </section>
  )
}

const CollectionSelector = ({ type, cards, theme }) => {
  switch(type) {
    case 'simple':
      return <SimpleCollection cards={cards} theme={theme} />
    case 'grid':
      return <GridCollection cards={cards} />
    case 'alternating':
      return <AlternatingCardCollection cards={cards} />
    case 'numbered':
      return <NumberedCollection cards={cards} />
    case 'card':
      return  <CardCollection cards={cards} theme={theme} />
    case 'alternating numbered':
      return <AlternatingNumberedCardCollection cards={cards} theme={theme} />
    default:
      return <DefaultCardCollection cards={cards} theme={theme} />
  }
}

const CardCollection = ({ cards }) => {
  if (!cards?.length > 0) {
    return 0
  }

  return (
    <ul
      css={css({
        listStyle: `none`,
        margin: `0 auto`,
        display: `grid`,
        gridTemplateColumns: `1fr  1fr`,
        gridGap: `${space[3]}px`,
        gridAutoRows: `160px`,
        maxWidth: `900px`,
        [mediaQueries.xl]: {
          gridTemplateColumns: `repeat(4, 1fr)`,
          gridAutoRows: `160px`,
        },
      })}
    >
      {cards?.map(card => {
        return (
          <li key={card.id}>
            <Link to={card.internalLink?.fields?.path} css={css({ textDecoration: `none`})}>
              <div
                css={css({
                  boxShadow: `0 5px 16px rgba(0, 0, 0, 0.1)`,
                  border: `1px solid ${colors.tertiary}`,
                  borderRadius: `4px`,
                  display: `flex`,
                  flexDirection: `column`,
                  width: `100%`,
                  height: `100%`,
                  cursor: `pointer`,
                  position: `relative`,
                  overflow: `hidden`,
                  '&:hover': {
                    backgroundColor: colors.lightBase,
                    img: {
                      filter: `unset`,
                      transitionProperty: `left opacity`,
                      transitionDuration: `600ms`,
                      left: `calc(50% - 2rem)`,
                    },
                    'h3,p,span': {
                      transitionProperty: `color`,
                      color: colors.white,
                      backgroundColor: colors.base,
                    },
                  },
                })}
              >
                <div
                  css={css({
                    flex: 1,
                    zIndex: 1,
                    display: `flex`,
                    flexxDirection: `column`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  })}
                >
                  <h3
                    css={css({
                      width: `100%`,
                      textAlign: `center`,
                      zIndex: 1,
                      backgroundColor: `${colors.white}c4`,
                      padding: `${space[2]}px ${space[3]}px`,
                      fontSize: fontSizes[1],
                      [mediaQueries.md]: {
                        fontSize: fontSizes[2],
                      },
                    })}
                  >
                    {card.title}
                  </h3>
                  {card.media && (
                    <img
                      src={card.media.file?.url}
                      alt={card.media.title}
                      css={css({
                        height: `75%`,
                        position: `absolute`,
                        left: `50%`,
                        bottom: 0,
                        zIndex: -1,
                        filter: `opacity(0.5)`,
                        transitionProperty: `left opacity`,
                        transitionDuration: `300ms`,
                        pointerEvents: `none`,
                        marginBottom: 0,
                      })}
                    />
                  )}
                </div>
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}


const GridCollection = ({ cards }) => {
  if(!cards?.length > 0) {
    return 0
  }

  return (
    <ul
      css={css({
        listStyle: `none`,
        margin: 0,
        padding: `${space[5]}px 0`,
        display: `grid`,
        gridTemplateColumns: `1fr`,
        gridGap: `${space[4]}px`,
        justifyContent: `center`,
        [mediaQueries.md]: {
          gridTemplateColumns: `repeat(auto-fit, 250px)`,
        },
        [mediaQueries.lg]: {
          gridTemplateColumns: `repeat(auto-fit, 350px)`,
        },
      })}
    >
      {cards.map(card => {
        return (
          <li
            key={card.id}
            css={css({
              height: `250px`,
              borderRadius: `8px`,
              overflow: `hidden`,
              [mediaQueries.lg]: {
                height: `450px`,
              },
            })}
          >
            <Link
              css={css({
                display: `block`,
                position: `relative`,
                height: `100%`,
                '&:hover, &:active, &:focus': {
                  '.card--background': {
                    transition: `all ease-in-out 0.25s`,
                    opacity: 1,
                  },
                  '.card--content': {
                    opacity: 1,
                  },
                  '.card--button': {
                    transform: `translate3d(0,0,0)`,
                  },
                  '.card--image': {
                    transform: `scale(1.25)`,
                  },
                },
              })}
              to={card.internalLink?.fields?.path}
            >
              <div
                className="card--content"
                css={css({
                  position: `absolute`,
                  top: 0,
                  left: 0,
                  width: `100%`,
                  height: `100%`,
                  padding: `${space[5]}px ${space[4]}px`,
                  zIndex: 2,
                  color: colors.white,
                  transition: `all ease-in-out 0.25s`,
                  opacity: 0,
                })}
              >
                <h3
                  css={css({
                    color: colors.primaryBrand,
                    marginBottom: `${space[4]}px`,
                  })}
                >
                  {card.title}
                </h3>
                {card.description?.childMarkdownRemark?.html && (
                  <div
                    css={css({
                      position: `relative`,
                    })}
                    dangerouslySetInnerHTML={{
                      __html: card.description.childMarkdownRemark.html,
                    }}
                  />
                )}
                <span
                  className="card--button"
                  css={css({
                    marginTop: `${space[4]}px`,
                    display: `inline-flex`,
                    borderRadius: `4px`,
                    border: `2px solid ${colors.primaryBrand}`,
                    padding: `${space[2]}px ${space[3]}px`,
                    transition: `all ease-in-out 0.25s`,
                    transform: `translate3d(0,-40px,0)`,
                  })}
                >
                  Learn more
                </span>
              </div>
              <div
                css={css({
                  backgroundColor: colors.base,
                  position: `absolute`,
                  width: `100%`,
                  height: `100%`,
                })}
              >
                {card.media?.fluid && (
                  <GatsbyImage
                    className="card--image"
                    fluid={card.media.fluid}
                    alt={card.media.title}
                    css={css({
                      position: `absolute !important`,
                      top: 0,
                      left: 0,
                      width: `100%`,
                      height: `100%`,
                      transform: `scale(1.05)`,
                      transition: `all ease-in-out 0.25s`,
                    })}
                  />
                )}
              </div>
              <div
                css={css({
                  position: `absolute`,
                  bottom: 0,
                  width: `100%`,
                  height: `100%`,
                  backgroundImage: `linear-gradient(180deg,rgba(0,0,0,0) 50%,rgb(0,0,0, 0.9) 100%) !important`,
                })}
              />
              <div
                css={css({
                  position: `absolute`,
                  padding: `${space[4]}px`,
                  bottom: 0,
                  color: colors.white,
                  fontWeight: `bold`,
                  width: `100%`,
                  fontSize: fontSizes[3],
                })}
              >
                <span>{card.title}</span>
              </div>
              <div
                className="card--background"
                css={css({
                  opacity: 0,
                  position: `absolute`,
                  height: `100%`,
                  width: `100%`,
                  top: 0,
                  left: 0,
                  backgroundImage: `linear-gradient(-180deg, rgba(6,6,6,0.70), rgba(6,6,6,1))`,
                  transition: `all ease-in-out 0.25s`,
                })}
              />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

const SimpleCollection = ({ cards, theme }) => {
  return (
    <div
      css={css({
        marginTop: `${space[5]}px`,
      })}
    >
      {cards?.length > 0 && (
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `grid`,
            gridTemplateColumns: `1fr`,
            gridGap: `${space[3]}px`,
            [mediaQueries.md]: {
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit,300px)`,
              gridGap: `${space[3]}px`,
              justifyContent: `center`,
            },
          })}
        >
          {cards.map(card => {
            return (
              <li key={card.id}>
                <Link
                  to={card.internalLink?.fields?.path}
                  css={css({
                    width: `100%`,
                    textDecoration: `none`,
                    backgroundColor:
                      theme !== `dark` ? colors.base : colors.white,
                    color: theme !== `dark` ? colors.white : colors.base,
                    padding: `${space[3]}px`,
                    textAlign: `center`,
                    fontSize: fontSizes[3],
                    fontWeight: `bold`,
                    borderRadius: `4px`,
                    display: `flex`,
                    justifyContent: `center`,
                  })}
                >
                  <span>{card.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const NumberedCollection = ({ cards, theme }) => {
  return (
    <div
      css={css({
        marginTop: `${space[5]}px`,
      })}
    >
      {cards?.length > 0 && (
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `grid`,
            gridTemplateColumns: `1fr`,
            gridGap: `${space[4]}px`,
            [mediaQueries.md]: {
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit,300px)`,
              gridGap: `${space[4]}px ${space[3]}px`,
              justifyContent: `center`,
            },
          })}
        >
          {cards.map((card, id) => {
            const contentType = card.media?.file?.contentType
            const isJpeg =
              contentType === `image/jpeg` || contentType === `image/png`
            return (
              <li
                key={card.id}
                css={css({
                  marginBottom: `${space[4]}px`,
                  display: `flex`,
                  flexDirection: `column`,
                  [mediaQueries.lg]: {
                    marginBottom: 0,
                  },
                })}
              >
                {card.media && !isJpeg && (
                  <div
                    css={css({
                      color: colors.primaryBrand,
                      fontSize: fontSizes[4],
                      marginBottom: `${space[4]}px`,
                    })}
                  >
                    <img
                      width="auto"
                      heighht="180"
                      css={css({
                        width: `auto`,
                        height: `180px`,
                      })}
                      loading="lazy"
                      alt={card.media?.title}
                      src={card.media?.file?.url}
                    />
                  </div>
                )}
                <div
                  css={css({
                    flex: 1,
                  })}
                >
                  <span
                    css={css({
                      fontSize: fontSizes[4],
                      fontWeight: `bold`,
                      color: `hsla(215,100%,17%,0.5)`,
                      marginBottom: `${space[2]}px`,
                      display: `block`,
                      [mediaQueries.md]: {
                        fontSize: fontSizes[5],
                      }
                    })}
                  >
                    0{id + 1}.
                  </span>
                  <div
                    css={css({
                      marginBottom: `${space[3]}px`,
                      display: `flex`,
                      alignItems: `center`,
                    })}
                  >
                    <p>
                      <strong
                        css={css({
                          fontSize: fontSizes[2],
                          fontWeight: `bold`,
                          lineHeight: lineHeights.heading,
                          [mediaQueries.lg]: {
                            fontSize: fontSizes[3],
                          },
                        })}
                      >
                        {card.title}
                      </strong>
                    </p>
                  </div>
                  {card.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.description.childMarkdownRemark.html,
                      }}
                      css={css({
                        lineHeight: `1.5`,
                        fontSize: `16px`,
                        strong: {
                          fontWeight: `600`,
                        },
                      })}
                    />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const AlternatingCardCollection = ({ cards }) => {
  return (
    <div
      css={css({
        marginTop: `${space[5]}px`,
      })}
    >
      {cards?.length > 0 && (
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `flex`,
            flexDirection: `column`,
          })}
        >
          {cards.map(card => {
            return (
              <li
                key={card.id}
                css={css({
                  display: `flex`,
                  flexDirection: `column`,
                  marginBottom: `${space[4]}px`,
                  [mediaQueries.md]: {
                    flexDirection: `row`,
                    ':nth-of-type(even)': {
                      flexDirection: `row-reverse`,
                      '.content': {
                        marginLeft: `auto`,
                      },
                    },
                  },
                })}
              >
                <div
                  css={css({
                    [mediaQueries.md]: {
                      width: `30%`,
                    },
                  })}
                >
                  {card.media?.fluid && (
                    <div
                      css={css({
                        marginBottom: `${space[4]}px`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                      })}
                    >
                      <GatsbyImage
                        fluid={card.media.fullWidth}
                        alt={card.media.title}
                      />
                    </div>
                  )}
                </div>
                <div
                  css={css({
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    padding: `${space[4]}px 0`,
                    [mediaQueries.md]: {
                      width: `70%`,
                      padding: `${space[5]}px`,
                    },
                  })}
                >
                  <div
                    css={css({
                      maxWidth: breakpoints.sm,
                    })}
                    className="content"
                  >
                    <h3
                      css={css({
                        fontSize: fontSizes[3],
                      })}
                    >
                      {card.title}
                    </h3>
                    {card.description?.childMarkdownRemark?.html && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card.description.childMarkdownRemark.html,
                        }}
                        css={css({
                          marginTop: `${space[3]}px`,
                          fontSize: `16px`,
                          ul: {
                            listStyle: `none`,
                            li: {
                              margin: `${space[2]}px 0`,
                            },
                            'li:before': {
                              content: `"—"`,
                              marginRight: `8px`,
                            },
                          },
                          p: {
                            marginBottom: `${space[3]}px`,
                          },
                        })}
                      />
                    )}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const AlternatingNumberedCardCollection = ({ cards }) => {
  return (
    <div
      css={css({
        marginTop: `${space[5]}px`,
      })}
    >
      {cards?.length > 0 && (
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `flex`,
            flexDirection: `column`,
          })}
        >
          {cards.map((card, id) => {
            return (
              <li
                key={card.id}
                css={css({
                  display: `flex`,
                  flexDirection: `column`,
                  marginBottom: `${space[4]}px`,
                  [mediaQueries.md]: {
                    flexDirection: `row`,
                    ':nth-of-type(even)': {
                      flexDirection: `row-reverse`,
                      '.content': {
                        marginLeft: `auto`,
                      },
                    },
                  },
                })}
              >
                <div
                  css={css({
                    position: `relative`,
                    [mediaQueries.md]: {
                      width: `40%`,
                    },
                  })}
                >
                  {card.media?.fluid && (
                    <div
                      css={css({
                        marginBottom: `${space[4]}px`,
                        position: `relative`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                      })}
                    >
                      <GatsbyImage
                        fluid={card.media.fullWidth}
                        alt={card.media.title}
                      />
                      <div
                        css={css({
                          position: `absolute`,
                          left: 64,
                          top: 0,
                          fontSize: `256px`,
                          transform: `translateX(-100%) rotate(-90deg)`,
                          color: `inherit`,
                          transformOrigin: `center center`,
                          fontWeight: `bold`,
                          opacity: 0.15,
                        })}
                      >
                        <span>0{id + 1}</span>
                      </div>
                    </div>
                  )}
                  <h3
                    css={css({
                      fontSize: fontSizes[3],
                      color: `inherit`,
                    })}
                  >
                    {card.title}
                  </h3>
                  {card.description?.childMarkdownRemark?.html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.description.childMarkdownRemark.html,
                      }}
                      css={css({
                        marginTop: `${space[3]}px`,
                        fontSize: `16px`,
                        ul: {
                          listStyle: `none`,
                          li: {
                            margin: `${space[2]}px 0`,
                          },
                          'li:before': {
                            content: `"—"`,
                            marginRight: `8px`,
                          },
                        },
                        p: {
                          marginBottom: `${space[3]}px`,
                        },
                      })}
                    />
                  )}
                  <Link
                    to={card.internalLink?.fields.path}
                    css={css({
                      border: `2px solid`,
                      borderRadius: `2px`,
                      padding: `${space[2]}px ${space[3]}px`,
                      textDecoration: `none`,
                      marginTop: `${space[3]}px`,
                      display: `inline-block`,
                    })}
                  >
                    Learn more
                  </Link>
                </div>
                <div
                  css={css({
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    padding: `${space[4]}px 0`,
                    [mediaQueries.md]: {
                      width: `60%`,
                      padding: `${space[5]}px`,
                    },
                  })}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const DefaultCardCollection = ({ cards }) => {
  return (
    <div
      css={css({
        marginTop: `${space[5]}px`,
      })}
    >
      {cards?.length > 0 && (
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `grid`,
            gridTemplateColumns: `1fr`,
            gridGap: `${space[5]}px ${space[4]}px`,
            [mediaQueries.md]: {
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit,300px)`,
              justifyContent: `center`,
            },
          })}
        >
          {cards.map(card => {
            return (
              <li key={card.id}>
                {card.media?.fluid && (
                  <div
                    css={css({
                      marginBottom: `${space[4]}px`,
                    })}
                  >
                    <GatsbyImage
                      fluid={card.media.fluid}
                      alt={card.media.title}
                    />
                  </div>
                )}
                <h5>{card.title}</h5>
                {card.description?.childMarkdownRemark?.html && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: card.description.childMarkdownRemark.html,
                    }}
                    css={css({
                      marginTop: `${space[3]}px`,
                      fontSize: fontSizes[1],
                    })}
                  />
                )}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}