import React from 'react'
import { css } from 'styled-components'
import Img from 'gatsby-image'

import { mediaQueries, breakpoints } from '../../utils/tokens'

export const FullBackgroundSection = ({ section }) => {
  return (
    <section>
      <div
        css={css({
          [mediaQueries.lg]: {
            margin: `auto`,
          },
        })}
      >
        {section.image && (
          <Img
            fluid={section.image.fluid}
            alt={section.image.alt}
            css={css({
              [mediaQueries.xxl]: {
                height: `100vh`,
              },
            })}
          />
        )}
      </div>
    </section>
  )
}