import React from 'react'
import { css } from 'styled-components'
import Img from 'gatsby-image'

import {
  space,
  mediaQueries,
  breakpoints,
  fontSizes,
  colors,
} from '../../utils/tokens'
import RichArticle from '../RichArticle'
import ImageComparisonBlock from '../CustomBlocks/ImageComparisonBlock'
import GalleryCarouselBlock from '../CustomBlocks/GalleryCarouselBlock'
import { turnPropertiesIntoPropsObject } from '../../utils/turnPropertiesIntoPropsObject'
import { FeatureListBlock } from '../CustomBlocks/FeatureListBlock'
import { TitleLineBreak } from '../TitleLineBreak'

export const ContentWithCustomBlockSection = ({ section }) => {

  const showCustomBlock = (customBlock) => {
    const { name, assets, properties, cards } = customBlock 
    if(name === `GalleryCarouselBlock`) {
      return (
        <div
          css={css({
            padding: `${space[4]}px`,
          })}
        >
          <GalleryCarouselBlock
            images={assets}
          />
        </div>
      )
    } else if(name === `FeatureListBlock` ) {
      const props = turnPropertiesIntoPropsObject(properties)
      return (
        <div
          css={css({
            padding: `${space[4]}px`,
            display: `flex`,
            flexDirection: `column`,
            height: `100%`,
            justifyContent: `center`,
          })}
        >
          <FeatureListBlock cards={cards} {...props} />
        </div>
      )
    } else if(name === `ImageBlock`) {
      const image = assets && assets[0]
      return (
        <div>
          {image && (
            <Img fluid={image.fluid} alt={image.title} />
          )}
        </div>
      )
    } else if(name === `ImageComparisonBlock`) {
      const props = turnPropertiesIntoPropsObject(properties)

      return <ImageComparisonBlock {...props} />
    }
  }

  const props = section.properties && turnPropertiesIntoPropsObject(section.properties)


  return (
    <section
      css={css({
        [mediaQueries.lg]: {
          maxWidth: breakpoints.xxxl,
          margin: `auto`,
        },
      })}
    >
      <div
        css={css({
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.lg]: {
            display: `flex`,
            flexDirection: props && props.reversed ? `row-reverse` : `row`,
          },
        })}
      >
        <div
          css={css({
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            [mediaQueries.lg]: {
              width: `50%`,
            },
          })}
        >
          <div
            css={css({
              padding: `${space[5]}px ${space[4]}px`,
              [mediaQueries.md]: {
                padding: `${space[5]}px`,
              },
              [mediaQueries.lg]: {
                padding: `${space[4]}px ${space[5]}px`,
                maxWidth: breakpoints.md,
                marginLeft: props && props.reversed ? 0 : `auto`,
              },
              [mediaQueries.xxl]: {
                padding: `${space[5]}px`,
              },
              [mediaQueries.xxl]: {
                padding: `${space[5]}px ${space[6]}px`,
              },
            })}
          >
            <h2
              css={css({
                fontWeight: `bold`,
                fontSize: fontSizes[3],
                marginBottom: 0,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[4],
                },
              })}
            >
              {section.title}
            </h2>
            <TitleLineBreak />
            {section.richTextBody && (
              <RichArticle body={section.richTextBody} />
            )}
          </div>
        </div>
        {section.customBlock && (
          <div
            css={css({
              [mediaQueries.lg]: {
                width: `50%`,
              },
            })}
          >
            {showCustomBlock(section.customBlock)}
          </div>
        )}
      </div>
    </section>
  )
}
