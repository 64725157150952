import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import Container from '../../components/Container'

import { fontSizes, space, mediaQueries, colors, breakpoints, lineHeights } from '../../utils/tokens'
import { sectionThemeStyle, text } from '../../utils/styles'
import { TitleLineBreak } from '../TitleLineBreak'

export const ProcessSection = props => {
  const { cards, title, subtitle, theme = "light" } = props

  const isGreaterThanFour = cards?.length > 4
  

  return (
    <section
      css={css({
        ...sectionThemeStyle[theme],
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px 0`,
        },
      })}
    >
      <Container>
        {title && (
          <>
            <h2
              css={css({
                ...text.sectionHeading,
              })}
            >
              {title}
            </h2>
            <TitleLineBreak center />
          </>
        )}
        {subtitle && (
          <div
            css={css({
              maxWidth: breakpoints.md,
              margin: `0 auto`,
            })}
          >
            <p css={css({ ...text.sectionSubHeading })}>
              {subtitle.internal?.content || subtitle}
            </p>
          </div>
        )}
        <div
          css={css({
            marginTop: `${space[5]}px`,
            [mediaQueries.md]: {
              display: `grid`,
              gridTemplateColumns: isGreaterThanFour ? `repeat(auto-fit, 300px)` : `repeat(auto-fit, 250px)`,
              justifyContent: `center`,
              gridGap: `${space[5]}px`,
              padding: `0 ${space[4]}px`,
            },
            [mediaQueries.xl]: {
              padding: `0`,
            },
          })}
        >
          {cards &&
            cards.map((card, id) => {
              const contentType = card.media?.file?.contentType
              const isJpeg = contentType === `image/jpeg` || contentType === `image/png`
              return (
                <div
                  key={id}
                  css={css({
                    marginBottom: `${space[4]}px`,
                    display: `flex`,
                    flexDirection: `column`,
                    [mediaQueries.lg]: {
                      marginBottom: 0,
                    },
                  })}
                >
                  {card.media && !isJpeg && (
                    <div
                      css={css({
                        color: colors.primaryBrand,
                        fontSize: fontSizes[4],
                        marginBottom: `${space[4]}px`,
                      })}
                    >
                      <img
                        width="auto"
                        heighht="180"
                        css={css({
                          width: `auto`,
                          height: `180px`,
                        })}
                        loading="lazy"
                        alt={card.media?.title}
                        src={card.media?.file?.url}
                      />
                    </div>
                  )}
                  <div
                    css={css({
                      flex: 1,
                    })}
                  >
                    <span
                      css={css({
                        fontSize: fontSizes[5],
                        fontWeight: `bold`,
                        color: `hsla(215,100%,17%,0.5)`,
                        marginBottom: `${space[2]}px`,
                        display: `block`,
                      })}
                    >
                      0{id + 1}.
                    </span>
                    <div
                      css={css({
                        marginBottom: `${space[3]}px`,
                        display: `flex`,
                        alignItems: `center`,
                      })}
                    >
                      <p>
                        <strong
                          css={css({
                            fontSize: fontSizes[3],
                            fontWeight: `bold`,
                            lineHeight: lineHeights.heading,
                          })}
                        >
                          {card.title}
                        </strong>
                      </p>
                    </div>
                    {card.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card.description.childMarkdownRemark.html,
                        }}
                        css={css({
                          lineHeight: `1.5`,
                          fontSize: `16px`,
                          strong: {
                            fontWeight: `600`,
                          },
                        })}
                      />
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </Container>
    </section>
  )
}

ProcessSection.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
}
