import React from 'react'
import { css } from 'styled-components'

import RichArticle from '../RichArticle'
import Container from '../Container'

import { space, mediaQueries, breakpoints } from '../../utils/tokens'

export const RichTextSection = ({
  section
}) => {
  return (
    <section>
      {section.richTextBody && (
        <Container
          css={css({
            padding: `${space[5]}px calc(${space[3]}px + ${space[2]}px)`,
            [mediaQueries.lg]: {
              padding: `${space[5]}px 0`,
            },
          })}
        >
          <div
            css={{
              maxWidth: `900px`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
          >
            <RichArticle body={section.richTextBody} />
          </div>
        </Container>
      )}
    </section>
  )
}