import React from 'react'
import { css } from 'styled-components'
import { colors, breakpoints, mediaQueries, space, fontSizes } from '../../utils/tokens'
import { ButtonBlock } from '../CustomBlocks/ButtonBlock'
import { turnPropertiesIntoPropsObject } from '../../utils/turnPropertiesIntoPropsObject'

export const CalloutSection = ({ section }) => {

  const { customBlock } = section

  const showButton = () => {
    if(!customBlock) {
      return null
    }

    if(customBlock.name === `ButtonBlock`) {
      const { properties } = customBlock
      const props = turnPropertiesIntoPropsObject(properties)
      return (
        <ButtonBlock
          {...props}
        />
      )
    }
  }

  return (
    <section
      id="#call-to-action"
      css={css({
        backgroundColor: colors.base
      })}
    >
      <div
        css={css({
          maxWidth: breakpoints.xl,
          margin: `0 auto`,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `center`,
          padding: `${space[5]}px ${space[4]}px`,
          [mediaQueries.lg]: {
            padding: `${space[6]}px`,
          }
        })}
      >
        <h2
          css={css({
            fontWeight: `bold`,
            fontSize: fontSizes[3],
            color: colors.white,
            textAlign: `center`,
            marginBottom: `${space[4]}px`,
            [mediaQueries.lg]: {
              fontSize: fontSizes[4]
            }
          })}
        >
          {section.title}
        </h2>
        {showButton()}
      </div>
    </section>
  )
}