import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import { css } from 'styled-components'
import { text } from '../../utils/styles'
import {
  breakpoints,
  colors,
  fontSizes,
  mediaQueries,
  palette,
  space,
} from '../../utils/tokens'
import { ButtonSelector } from '../ButtonSelector'
import Container from '../Container'

export const PricingTableSection = ({ section }) => {
  if (!section) {
    return null
  }

  const { title, subtitle,  pricingCards, theme } = section

  const sectionThemeStyle = {
    dark: {
      backgroundColor: colors.base,
      color: `white`,
    },
    light: {
      backgroundColor: `white`,
      color: colors.base,
    },
    primary: {
      backgroundColor: colors.primaryBrand,
      color: colors.white,
    },
  }

  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        color: colors.base,
        ...sectionThemeStyle[theme],
      })}
    >
      <Container
        css={{
          maxWidth: breakpoints.xxl,
        }}
      >
        {title && (
          <h2
            css={css({
              ...text.sectionHeading,
            })}
          >
            {title}
          </h2>
        )}
        {subtitle && (
          <div
            css={css({
              color: `inherit`,
              fontSize: fontSizes[2],
              textAlign: `center`,
              fontWeight: `normal`,
              maxWidth: breakpoints.md,
              margin: `0 auto`,
              marginTop: `${space[3]}px`,
            })}
          >
            {renderRichText(subtitle)}
          </div>
        )}
        {pricingCards?.length > 0 && (
          <ul
            css={css({
              marginTop: `${space[4]}px`,
              listStyle: `none`,
              display: `grid`,
              gridGap: `${space[3]}px`,
              justifyContent: `center`,
              [mediaQueries.md]: {
                gridTemplateColumns: `repeat(auto-fit, 400px)`,
              },
            })}
          >
            {pricingCards.map(card => {
              return (
                <li key={card.id}>
                  <PricingCard card={card} />
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}

const PricingCard = ({ card }) => {
  const {
    title,
    subtitle,
    image,
    price,
    buttons,
    whatsIncluded,
    whatsNotIncluded,
    pricingModel,
  } = card

  const listStyle = {
    ul: {
      listStyle: `none`,
    },
    p: {
      fontSize: fontSizes[2],
    },
  }

  const renderPricing = () => {
    switch(pricingModel) {
      case 'Starting Price': {
        return (
          <div
            css={css({
              textAlign: `center`,
              padding: `${space[3]}px 0`,
            })}
          >
            <span
              css={css({
                color: palette.grey[70],
                fontWeight: `500`,
                fontSize: fontSizes[1],
                display: `block`,
                marginBottom: `${space[2]}px`,
              })}
            >Starting at </span>
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                color: colors.primaryBrand,
              })}
            >${`${price}`}</span>
          </div>
        )
      }
      default:
        return (
          <div
            css={css({
              textAlign: `center`,
              padding: `${space[3]}px 0`,
            })}
          >
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                color: colors.primaryBrand,
              })}
            >
              ${`${price}`}
            </span>
          </div>
        )
    }
  }
  return (
    <div
      css={css({
        borderRadius: `16px`,
        border: `1px solid ${palette.grey[50]}`,
        padding: `${space[5]}px ${space[4]}px`,
        height: `100%`,
      })}
    >
      {image && (
        <img
          src={image.file?.url}
          alt={image.title}
          css={css({
            width: `50%`,
            margin: `auto`,
            marginBottom: `${space[4]}px`,
          })}
        />
      )}
      <h3
        css={{
          textAlign: `center`,
          marginBottom: `${space[2]}px`,
          fontSize: fontSizes[2],
          [mediaQueries.lg]: {
            fontSize: fontSizes[3],
          },
        }}
      >
        {title}
      </h3>
      {subtitle && (
        <div
          css={css({
            marginBottom: `${space[3]}px`,
            fontSize: fontSizes[1],
            textAlign: `center`,
          })}
        >
          {renderRichText(subtitle)}
        </div>
      )}
      <div>{renderPricing()}</div>
      <div
        css={css({
          ...listStyle,
          li: {
            display: `flex`,
            alignItems: `center`,
            position: `relative`,
            p: {
              marginLeft: `calc(${space[3]}px + ${space[2]}px)`,
            },
            '&::before': {
              content: '"+"',
              fontWeight: `bold`,
              position: `absolute`,
              top: `4px`,
              color: colors.primaryBrand,
            },
          },
        })}
      >
        {whatsIncluded && renderRichText(whatsIncluded)}
      </div>
      <div
        css={css({
          ...listStyle,
          opacity: 0.5,
          li: {
            display: `flex`,
            alignItems: `center`,
            position: `relative`,
            p: {
              marginLeft: `calc(${space[3]}px + ${space[2]}px)`,
            },
            '&::before': {
              content: '"-"',
              fontWeight: `bold`,
              position: `absolute`,
              top: `4px`,
              marginLeft: `3px`,
              color: colors.primaryBrand,
            },
          },
        })}
      >
        {whatsNotIncluded && renderRichText(whatsNotIncluded)}
      </div>
      <div
        css={css({
          maxWidth: breakpoints.sm,
          margin: `0 auto`,
          marginTop: `${space[4]}px`,
          display: `flex`,
          justifyContent: `center`,
        })}
      >
        {buttons?.length > 0 &&
          buttons.map(button => {
            return (
              <div
                key={button.id}
                css={css({
                  width: `100%`,
                })}
              >
                <ButtonSelector
                  button={button}
                  css={css({
                    width: `100%`,
                    [mediaQueries.md]: { width: `auto` },
                  })}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}
