import React from 'react'
import { css } from 'styled-components'
import { breakpoints, mediaQueries, space } from '../../utils/tokens'
import Container from '../Container'

export const ClientsSection = ({ section }) => {
  if(!section) {
    return null
  }
  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
      })}
    >
      <Container
        css={css({
          maxWidth: breakpoints.xxl,
        })}
      >
        <ul
          css={css({
            listStyle: `none`,
            margin: 0,
            padding: 0,
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            gridGap: `${space[3]}px`,
            [mediaQueries.md]: {
              gridTemplateColumns: `repeat(auto-fit, minmax(250px, auto))`,
            },
          })}
        >
          {section?.companyLogos?.length > 0 &&
            section.companyLogos.map(logo => {
              return (
                <li
                  key={logo.id}
                  css={css({
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    height: `80px`,
                    [mediaQueries.md]: {
                      height: `auto`,
                    }
                  })}
                >
                  <img
                    src={logo.file?.url}
                    alt={logo.title}
                    css={css({
                      width: `auto`,
                      margin: `0 auto`,
                      maxHeight: `128px`,
                      maxWidth: `96px`,
                    })}
                  />
                </li>
              )
            })}
        </ul>
      </Container>
    </section>
  )
}