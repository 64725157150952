import React, { useState, useCallback } from 'react'
import { css } from 'styled-components'
import { FaStar } from 'react-icons/fa'

import Lightbox from 'react-image-lightbox'

import Container from '../../components/Container'

import { fontSizes, space, mediaQueries, colors, palette, breakpoints } from '../../utils/tokens'

import { sectionThemeStyle, text } from '../../utils/styles'
import { TitleLineBreak } from '../TitleLineBreak'
import GatsbyImage from 'gatsby-image'

const ClientReviewsSection = ({ section }) => {
  if(!section) {
    return null
  }
  const { reviews, title, theme = 'light' } = section

  const starStyle = {
    color: palette.orange[40]
  }

  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px ${space[5]}px`,
        },
      })}
    >
      <div
        css={css({
          ...sectionThemeStyle[theme],
        })}
      >
        <Container>
          {title && (
            <div>
              <h2
                css={css({
                  ...text.sectionHeading,
                })}
              >
                {title}
              </h2>
              <TitleLineBreak center />
            </div>
          )}
          <div
            css={css({
              marginTop: `${space[4]}px`,
              [mediaQueries.md]: {
                marginTop: `${space[5]}px`,
                padding: `0 ${space[5]}px`,
              },
            })}
          >
            {reviews?.length > 0 && (
              <ul
                css={css({
                  display: `flex`,
                  flexFlow: `wrap`,
                  gridGap: `${space[3]}px`,
                  listStyle: `none`,
                  justifyContent: `center`,
                  maxWidth: breakpoints.md,
                  margin: `0 auto`,
                })}
              >
                {reviews.map(review => {
                  const { images } = review
                  return (
                    <li
                      key={review.id}
                      css={css({
                        padding: `${space[4]}px ${space[3]}px`,
                        borderBottom: `1px solid`,
                        borderColor: palette.grey[50],
                        gridGap: `${space[3]}px`,
                        [mediaQueries.lg]: {
                          marginBottom: 0,
                        },
                      })}
                    >
                      {images && <ReviewImages images={images} />}
                      <div>
                        <div css={css({ marginBottom: `${space[3]}px` })}>
                          <FaStar css={starStyle} />
                          <FaStar css={starStyle} />
                          <FaStar css={starStyle} />
                          <FaStar css={starStyle} />
                          <FaStar css={starStyle} />
                        </div>
                        {review.content && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: review.content.childMarkdownRemark.html,
                            }}
                          />
                        )}
                        {review.reviewer && (
                          <div>
                            <p
                              css={{
                                marginTop: `${space[4]}px`,
                                fontWeight: `bold`,
                              }}
                            >
                              — {review.reviewer}
                            </p>
                          </div>
                        )}
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </Container>
      </div>
    </section>
  )
}

const ReviewImages = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div
      css={css({
        display: `flex`,
        marginBottom: `${space[3]}px`,
        flexFlow: `wrap`,
      })}
    >
      {images?.map((image, id) => {
        return (
          <div
            key={image.id}
            css={css({
              width: `80px`,
              marginRight: `${space[2]}px`,
              cursor: `zoom-in`,
              marginBottom: `${space[2]}px`,
            })}
            onClick={(e) => openLightbox(e, { index: id })}
            tabIndex={0}
          >
            <GatsbyImage fluid={image.fluid} alt={image.title} />
          </div>
        )
      })}
      {viewerIsOpen ? (
        <Lightbox
          mainSrc={images[currentImage].lightBox.src}
          nextSrc={images[(currentImage + 1) % images.length].lightBox.src}
          imageTitle={images[currentImage].title}
          prevSrc={
            images[(currentImage + images.length - 1) % images.length].lightBox.src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % images.length)
          }
          imagePadding={64}
          enableZoom={false}
        />
      ) : null}
    </div>
  )
}

export default ClientReviewsSection
