import React from 'react'
import { css } from 'styled-components'
import { fontSizes, colors, space, mediaQueries } from '../../utils/tokens'

export const FeatureListBlock = ({
  cards,
  title
}) => {
  return (
    <div
      css={css({
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`
      })}
    >
      <h5
        css={css({
          fontSize: fontSizes[3],
          fontWeight: `bold`,
          marginBottom: `${space[4]}px`,
        })}
      >{title}</h5>
      <ul
        css={css({
          listStyle: `none`,
          display: `flex`,
          flexFlow: `wrap`,
          width: `100%`,
        })}
      >
        {cards && cards.map((card) => {
          return (
            <li
              key={card.id}
              css={css({
                width: `50%`,
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
                [mediaQueries.lg]: {
                  width: `25%`,
                }
              })}
            >
              <span
                css={css({
                  fontWeight: `bold`,
                  display: `block`,
                  fontSize: fontSizes[3],
                  color: colors.primaryBrand,
                  marginBottom: `${space[2]}px`,
                })}
              >{card.title}</span>
              {card.media && (
                <img
                  alt={card.media.title}
                  src={card.media.file.url}
                  css={css({
                    height: `48px`,
                    width: `auto`,
                    maxWidth: `64px`,
                    marginBottom: `${space[3]}px`,
                  })}
                />
              )}
              {card.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.description.childMarkdownRemark.html
                  }}
                />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}