import React from 'react'
import { css } from 'styled-components'

import { useInView } from 'react-intersection-observer'
import { breakpoints, fontSizes, mediaQueries, space } from '../../utils/tokens'
import GoogleMapEmbed from '../GoogleMapEmbed'
import Container from '../Container'

export const LocationsSectionNYC = () => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
  })

  const addressStyle = {
    display: `inline-block`,
    marginBottom: `${space[3]}px`,
  }

  const cardStyle = {
    backgroundColor: `white`,
    boxShadow: `0 5px 16px rgba(0, 0, 0, 0.1)`,
    padding: `${space[4]}px`,
    borderRadius: `4px`,
  }

  const titleStyle = {
    fontSize: fontSizes[3],
    marginBottom: `${space[3]}px`,
  }

  return (
    <Container
      css={css({
        maxWidth: breakpoints.xl,
      })}
    >
      <div
        ref={ref}
        css={css({
          display: `grid`,
          gridGap: `${space[3]}px`,
          [mediaQueries.md]: {
            gridTemplateColumns: `1fr 1fr`,
          },
        })}
      >
        <section
          css={css`
            background-color: white;
            box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 4px;
          `}
        >
          <h5 css={css(titleStyle)}>Williamsburg Office</h5>
          <address css={css(addressStyle)}>
            252 Driggs Ave, Brooklyn, NY 11222
          </address>
          <p>By appointment only.</p>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_queens"
              title="Google Map of PrintAWorld Factory"
              src="https://maps.google.com/maps?q=252%20Driggs%20Ave,%20Brooklyn,%20NY%2011222&t=&z=13&ie=UTF8&iwloc=&output=embed"
            />
          )}
        </section>
        <section css={css(cardStyle)}>
          <h5 css={css(titleStyle)}>Brooklyn Office</h5>
          <address css={css(addressStyle)}>
            394 McGuinness Blvd #302, Brooklyn, NYC 11222
          </address>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_brooklyn"
              title="Google Map of PrintAWorld"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.1596481449897!2d-73.95454018419387!3d40.736512479329164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2593be7da31a7%3A0x894d37cca18ef165!2sPrintAWorld!5e0!3m2!1sen!2sua!4v1565358703290!5m2!1sen!2sua"
            />
          )}
        </section>
        <section css={css(cardStyle)}>
          <h5 css={css(titleStyle)}>Queens Office</h5>
          <address css={css(addressStyle)}>
            74-12 88th St, Glendale, NYC 11385
          </address>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_queens"
              title="Google Map of PrintAWorld Factory"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.467301527987!2d-73.8655169845952!3d40.70772827933233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25fe1a7f49f8d%3A0xf0bcf2f2f7f71d4!2sPrintAWorld%20Factory!5e0!3m2!1sen!2sus!4v1567036070809!5m2!1sen!2sus"
            />
          )}
        </section>
      </div>
    </Container>
  )
}