import React from 'react'
import { css } from 'styled-components'

import { mediaQueries, space } from '../utils/tokens'

import WhyUsSection from './Section/WhyUsSection'
import { AlternatingRowsSection } from './Section/AlternatingRowsSection'
import { ContentWithCustomBlockSection } from './Section/ContentWithCustomBlockSection'
import { FeatureListSection } from './Section/FeatureListSection'
import { GridSection } from './Section/GridSection'
import MaterialsListSection from './Section/MaterialsListSection'
import ApplicationSection from './Section/ApplicationSection'
import { HowItWorksSectionNew } from './Section/HowItWorksSectionNew'
import PricingSection from './Section/PricingSection'
import { CalloutSection } from './Section/CalloutSection'
import { FullBackgroundSection } from './Section/FullBackgroundSection'
import { ProcessSection } from './Section/ProcessSection'
import { ClientsSection } from './Section/ClientsSection'
import { CallToActionSection } from './Section/CallToActionSection'
import { CardCollectionSection } from './Section/CardCollectionSection'
import { UIReferenceSection } from './Section/UIReferenceSection'
import ClientReviewsSection from './Section/ClientReviewsSection'
import { PricingTableSection } from './Section/PricingTableSection'
import { TextOnlySection } from './Section/TextOnlySection'
import { GallerySection } from './Section/GallerySection'
import { TextWithBlockSection } from './Section/TextWithBlockSection'
import { ContentWithPhotoSection } from './Section/ContentWithPhotoSection'
import { RichTextSection } from './Section/RichTextSection'

export const SectionSelector = ({
  sections
}) => {

  return sections.map((section) => {
    if (section.__typename === `ContentfulSectionGallery`) {
      return (
        <GallerySection section={section} key={section.id} />
      )
    } else if (section.__typename === `ContentfulSectionClients`) {
      return (
        <ClientsSection section={section} key={section.id} />
      )
    } else if (section.__typename === `ContentfulSectionTextWithBlock`) {
      return (
        <TextWithBlockSection section={section} key={section.id} />
      )
    } else if (section.__typename === `ContentfulSectionCallToAction`) {
      return (
        <CallToActionSection section={section} key={section.id} />
      )
    } else if (section.__typename === `ContentfulSectionCardCollection`) {
      return <CardCollectionSection section={section} key={section.id} />
    } else if (section.__typename === `ContentfulSectionTextOnly`) {
      return <TextOnlySection section={section} key={section.id} />
    } else if (section.__typename === `ContentfulSectionPricingTable`) {
      return <PricingTableSection section={section} key={section.id} />
    }else if (section.__typename === `ContentfulSectionUiReference`) {
      return <UIReferenceSection section={section} key={section.id} />
    } else if(section.__typename === `ContentfulSectionClientReviews`) {
      return <ClientReviewsSection section={section} key={section.id} />
    } else {
      if (section.type === `WhyUsSection`) {
        return (
          <WhyUsSection
            key={section.id}
            cards={section.cards}
            title={section.title}
          />
        )
      } else if (section.type === `AlternatingRowsSection`) {
        return <AlternatingRowsSection key={section.id} section={section} />
      } else if (section.type === `ContentWithPhotoSection`) {
        return (
          <ContentWithPhotoSection key={section.id} section={section} />
        )
      } else if (section.type === `RichTextSection`) {
        return <RichTextSection key={section.id} section={section} />
      } else if (section.type === `ContentWithCustomBlockSection`) {
        return (
          <ContentWithCustomBlockSection key={section.id} section={section} />
        )
      } else if (section.type === `FeatureListSection`) {
        return (
          <FeatureListSection
            key={section.id}
            cards={section.cards}
            title={section.title}
            subTitle={section.body && section.body.internal.content}
          />
        )
      } else if (section.type === `GallerySection`) {
        return (
          <GallerySection
            key={section.id}
            section={{
              images: section.gallery,
              title: `Some of our recent work`,
            }}
          />
        )
      } else if (section.type === `ProcessSection`) {
        return (
          <ProcessSection
            cards={section.cards}
            title={section.title}
            subtitle={section.subtitle}
            key={section.id}
          />
        )
      } else if (section.type === `FullBackgroundSection`) {
        return <FullBackgroundSection section={section} key={section.id} />
      } else if (section.type === `CalloutSection`) {
        return <CalloutSection section={section} key={section.id} />
      } else if (section.type === `GridSection`) {
        return <GridSection key={section.id} section={section} />
      } else if (section.type === `MaterialsSection`) {
        return (
          <MaterialsListSection
            key={section.id}
            materials={section.cards}
            section={section}
          />
        )
      } else if (section.type === `HowItWorksSection`) {
        return (
          <HowItWorksSectionNew
            cards={section.cards}
            title={section.title}
            key={section.id}
          />
        )
      } else if (section.type === `PricingSection`) {
        return <PricingSection section={section} key={section.id} />
      } else if (section.type === 'use case') {
        return (
          <ApplicationSection
            key={section.id}
            section={section}
            css={css({
              padding: `${space[4]}px`,
              [mediaQueries.lg]: {
                padding: `${space[5]}px`,
              },
            })}
          />
        )
      }
    }
    }
  )
}