import React from 'react'
import { css } from 'styled-components'
import { space, mediaQueries, breakpoints, fontSizes, letterSpacings, colors, palette } from '../../utils/tokens'
import { Background } from '../StyledComponents'

export const HowItWorksSectionNew = ({ cards, title }) => {
  return (
    <section
      css={css`
        padding: ${space[5]}px ${space[4]}px;

        ${mediaQueries.lg} {
          padding: ${space[6]}px 0;
        }
      `}
    >
      <Background>
        <h2
          css={css({
            fontSize: fontSizes[3],
            fontWeight: `bold`,
            marginBottom: `${space[5]}px`,
            textAlign: `center`,
            [mediaQueries.lg]: {
              fontSize: fontSizes[4],
            },
          })}
        >
          {title || `How it works`}
        </h2>
        {cards && (
          <ol
            css={css({
              listStyle: `none`,
              display: `grid`,
              gridGap: `${space[5]}px`,
              [mediaQueries.lg]: {
                display: `flex`,
                flexWrap: `wrap`,
                maxWidth: breakpoints.xl,
                margin: `0 auto`,
              },
            })}
          >
            {cards.map((card, index) => {
              return (
                <li
                  key={card.id}
                  css={css({
                    display: `flex`,
                    flexDirection: `column`,
                    position: `relative`,
                    [mediaQueries.lg]: {
                      width: `calc(33% - ${space[4]}px)`,
                      margin: `${space[4]}px auto`,
                    },
                  })}
                >
                  {card.icon && (
                    <img
                      src={card.icon.file.url}
                      alt={card.icon.title}
                      css={css({
                        maxHeight: `150px`,
                        marginBottom: `${space[4]}px`,
                      })}
                    />
                  )}
                  <div
                    css={css({
                      display: `grid`,
                      gridTemplateColumns: `auto 1fr`,
                      alignItems: `center`,
                    })}
                  >
                    <div
                      css={css({
                        borderRadius: `100%`,
                        backgroundColor: colors.primaryBrand,
                        width: `32px`,
                        height: `32px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        marginRight: `${space[3]}px`,
                      })}
                    >
                      <span
                        css={css({
                          fontWeight: `bold`,
                          color: colors.white,
                          marginTop: `2px`,
                        })}
                      >
                        {index + 1}
                      </span>
                    </div>
                    <h5
                      css={css({
                        color: `inherit`,
                        fontSize: fontSizes[3],
                        fontWeight: `bold`,
                        textAlign: `left`,
                        [mediaQueries.xxl]: {
                          width: `100%`,
                        },
                      })}
                    >
                      {card.title}
                    </h5>
                  </div>

                  {card.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.description.childMarkdownRemark.html,
                      }}
                      css={css({
                        color: `inherit`,
                        marginTop: `${space[3]}px`,
                        letterSpacing: letterSpacings.normal,
                        fontSize: fontSizes[2],
                      })}
                    />
                  )}
                </li>
              )
            })}
          </ol>
        )}
      </Background>
    </section>
  )
}
