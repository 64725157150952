import React from 'react'
import { css } from 'styled-components'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { GiCheckMark } from 'react-icons/gi'
import Container from '../../components/Container'

import { space, mediaQueries, breakpoints, palette, fontSizes, colors } from '../../utils/tokens'

import { sectionThemeStyle, text } from '../../utils/styles'

import { GalleryBlockCarousel } from '../Blocks/GalleryBlockCarousel'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { TitleLineBreak } from '../TitleLineBreak'
import { GatsbyImage } from 'gatsby-plugin-image'

export const TextWithBlockSection = ({ section }) => {
  if(!section) {
    return null
  }

  const { title, subtitle, body, block, type, theme = 'light' } = section

  return (
    <section
      css={css({
        position: `relative`,
        ...sectionThemeStyle[theme],
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px ${space[5]}px`,
        },
      })}
    >
      <Container
        css={css({
          zIndex: 1,
          maxWidth: breakpoints.xxl,
        })}
      >
        <div
          css={css({
            display: `flex`,
            flexDirection: `column-reverse`,
            [mediaQueries.lg]: {
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
              gridGap: `${space[4]}px`,
            },
          })}
        >
          <div
            css={css({
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              maxWidth: breakpoints.sm,
              zIndex: 1,
              margin: `0 auto`,
              gridColumn: type === `reversed` ? 2 : 1,
            })}
          >
            {title && (
              <>
                <h2
                  css={css({
                    fontWeight: `bold`,
                    fontSize: fontSizes[3],
                    textAlign: `left`,
                    marginLeft: 0,
                  })}
                >
                  {title}
                </h2>
                <TitleLineBreak />
              </>
            )}
            {subtitle && (
              <p
                css={css({
                  ...text.sectionSubHeading,
                  textAlign: `left`,
                  marginLeft: 0,
                })}
              >
                {subtitle}
              </p>
            )}
            {body && (
              <div
                css={css({
                  marginTop: `${space[4]}px`,
                  p: { marginBottom: `${space[3]}px` },
                })}
              >
                {renderRichText(body, options)}
              </div>
            )}
          </div>
          <div
            css={css({
              marginBottom: `${space[4]}px`,
              gridRow: 1,
              gridColumn: type === `reversed` ? 1 : 2,
            })}
          >
            {block && <BlockSelector block={block} type={type} />}
          </div>
        </div>
      </Container>
    </section>
  )
}

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul
          css={css({
            marginTop: `${space[3]}px`,
            marginBottom: `${space[3]}px`,
            listStyle: `none`,
          })}
        >
          {children}
        </ul>
      )
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li
          css={css({
            marginBottom: `${space[2]}px`,
            display: `flex`,
            alignItems: `baseline`,
            '> :first-child': {
              marginBottom: 0,
            },
            p: {
              marginBottom: 0,
            }
          })}
        >
          <span css={css({ marginRight: `${space[2]}px`, fontSize: fontSizes[0], color: colors.success })}><GiCheckMark /></span>
          <div>{children}</div>
        </li>
      )
    },
  },
}

const BlockImage = ({ image, altText, type }) => {
  if(!image) {
    return null
  }
  return (
    <div
      css={css({
        position: `relative`,
        [mediaQueries.lg]: {
          position: `static`,
        },
      })}
    >
      <div
        css={css({
          position: `absolute`,
          bottom: `32px`,
          marginLeft: `-32px`,
          minWidth: `75%`,
          height: `calc(100% + 64px)`,
          backgroundColor: palette.orange[20],
          [mediaQueries.lg]: {
            top: 0,
            marginLeft: 0,
            left: type === `reversed` ? 0 : `unset`,
            right: type === `reversed` ? `unset` : 0,
            height: `100%`,
            minWidth: `40%`,
          },
        })}
      />
      <GatsbyImage image={image?.gatsbyImageData} alt={altText} />
    </div>
  )
}

const BlockSelector = ({ block, type }) => {
  switch(block.__typename) {
    case 'ContentfulBlockGallery':
      return <GalleryBlockCarousel images={block.images} />
    case 'ContentfulBlockImage': {
      return <BlockImage image={block.image} altText={block.altText} type={type} />
    }
    default:
      return null
  }
}
