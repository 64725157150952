import { graphql } from 'gatsby'
import React, { useState, useCallback } from 'react'
import GatsbyImage from 'gatsby-image'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-image-lightbox'

import { text } from '../../utils/styles'

import { breakpoints, fontSizes, mediaQueries, space } from '../../utils/tokens'
import Container from '../Container'
import { TitleLineBreak } from '../TitleLineBreak'
import { css } from 'styled-components'

export const GallerySection = ({ section }) => {
  if(!section) {
    return null
  }

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  let galleryPhotos = []

  if(section?.images?.length > 0) {
    for(var i = 0; i < section.images.length; i++) {
      const image = section.images[i]
      
      galleryPhotos[i] = {
        src: image.lightBoxImage?.src,
        width: image?.file?.details?.image?.width,
        height: image?.file?.details?.image?.height,
        fluid: image?.fluid,
        key: image.id,
        title: image.title,
      }
    }
  } 

  return (
    <section
      css={{
        padding: `${space[4]}px ${space[4]}px`,
        [mediaQueries.md]: {
          padding: `80px ${space[4]}px`,
        },
      }}
    >
      <Container
        css={{
          maxWidth: breakpoints.xxl,
          [mediaQueries.md]: {
            padding: `0 ${space[5]}px`,
          },
        }}
      >
        <div
          css={{
            maxWidth: breakpoints.md,
            margin: `0 auto`,
          }}
        >
          {section.title && (
            <h2
              css={{
                fontSize: fontSizes[3],
                textAlign: `center`,
                [mediaQueries.md]: {
                  fontSize: fontSizes[4],
                },
              }}
            >
              {section.title}
            </h2>
          )}
          {section.title && <TitleLineBreak center />}
          {section.subtitle && (
            <div
              css={css({
                maxWidth: breakpoints.md,
                margin: `0 auto`,
                marginBottom: `${space[4]}px`,
              })}
            >
              <p css={css({ ...text.sectionSubHeading })}>
                {section.subtitle}
              </p>
            </div>
          )}
        </div>
        <div
          css={{
            margin: `0 -8px`,
          }}
        >
          <Container>
            <Gallery
              photos={galleryPhotos}
              targetRowHeight={300}
              margin={8}
              renderImage={({
                index,
                left,
                top,
                key,
                photo,
                margin,
                onClick,
              }) => {
                return (
                  <Photo
                    key={key}
                    index={index}
                    photo={photo}
                    left={left}
                    top={top}
                    margin={margin}
                    onClick={onClick}
                  />
                )
              }}
              onClick={openLightbox}
            />
            <div
              css={css({
                '.lightbox-wrapper': {
                  '.ril__image': {
                    height: `900px`,
                  },
                },
              })}
            >
              {viewerIsOpen ? (
                <Lightbox
                  mainSrc={galleryPhotos[currentImage].src}
                  nextSrc={
                    galleryPhotos[(currentImage + 1) % galleryPhotos.length].src
                  }
                  imageTitle={galleryPhotos[currentImage].title}
                  prevSrc={
                    galleryPhotos[
                      (currentImage + galleryPhotos.length - 1) %
                        galleryPhotos.length
                    ].src
                  }
                  onCloseRequest={closeLightbox}
                  onMovePrevRequest={() =>
                    setCurrentImage(
                      (currentImage + galleryPhotos.length - 1) %
                        galleryPhotos.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setCurrentImage((currentImage + 1) % galleryPhotos.length)
                  }
                  imagePadding={64}
                  enableZoom={false}
                />
              ) : null}
            </div>
          </Container>
        </div>
      </Container>
    </section>
  )
}

const Photo = ({ photo, margin, index, top, left, onClick }) => {
  const handleClick = event => {
    onClick(event, { photo, index })
  }
  return (
    <figure
      css={{
        margin: `${margin}px`,
        height: photo.height,
        width: photo.width,
        position: `relative`,
        overflow: `hidden`,
        cursor: `zoom-in`,
      }}
      onClick={onClick ? handleClick : null}
    >
      <GatsbyImage fluid={photo.fluid} alt={photo.title} />
    </figure>
  )
}

export const contentfulSectionGalleryFragment = graphql`
  fragment SectionGallery on ContentfulSectionGallery {
    id
    title
    subtitle
    images {
      id
      title
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
      lightBoxImage: fluid(maxWidth: 1440, quality: 80) {
        src
      }
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`