import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import { css } from 'styled-components'
import { BLOCKS } from '@contentful/rich-text-types';
import GatsbyImage from 'gatsby-image'

import { breakpoints, colors, fontSizes, mediaQueries, space } from '../../utils/tokens'
import Container from '../Container'
import { sectionThemeStyle, text } from '../../utils/styles';
import { BlockVideo } from '../BlockVideo';
import { GalleryBlockCarousel } from '../Blocks/GalleryBlockCarousel'


export const TextOnlySection = ({ section }) => {
  if(!section) {
    return null
  }

  console.log(section)

  const { title, subtitle, body, theme = 'light' } = section
  return (
    <section
      css={css({
        ...sectionThemeStyle[theme],
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px ${space[5]}px`,
        },
      })}
    >
      <Container
        css={css({
          maxWidth: breakpoints.md,
        })}
      >
        {title && (
          <>
            <h2
              css={css({
                ...text.sectionHeading,
                textAlign: `left`,
                marginLeft: 0,
                maxWidth: `unset`,
                [mediaQueries.md]: {
                  fontSize: fontSizes[4],
                },
              })}
            >
              {title}
            </h2>
          </>
        )}
        {subtitle && (
          <p
            css={css({
              ...text.sectionSubHeading,
              marginBottom: `${space[4]}px`,
              textAlign: `left`,
              marginLeft: 0,
              maxWidth: `unset`,
              [mediaQueries.md]: {
                fontSize: fontSizes[2],
              },
            })}
          >
            {subtitle}
          </p>
        )}
        {body && (
          <div
            css={css({
              marginTop: `${space[4]}px`,
            })}
          >
            {renderRichText(body, options)}
          </div>
        )}
      </Container>
    </section>
  )
}

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { description, fluid, title } = node.data.target

      if (!fluid) {
        return null
      }

      return (
        <figure
          css={css({
            marginBottom: `${space[4]}px`,
            display: `flex`,
            flexDirection: `column`,
          })}
        >
          <GatsbyImage
            alt={title}
            fluid={fluid}
            css={css({
              marginBottom: `${space[3]}px`,
            })}
          />
          {description && (
            <figcaption
              css={css({
                fontStyle: `italic`,
                textAlign: `center`,
              })}
            >
              {description}
            </figcaption>
          )}
        </figure>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p css={css({ margin: `${space[3]}px 0` })}>{children}</p>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5
        css={css({
          fontSize: fontSizes[2],
          margin: `${space[4]}px 0 ${space[3]}px`,
        })}
      >
        {children}
      </h5>
    ),
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul css={css({ marginLeft: `${space[4]}px` })}>{children}</ul>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const __typename = node?.data?.target?.__typename || ''

      if(__typename === `ContentfulBlockVideo`) {
        const url = node.data.target.url || node.data.target.media?.file?.url
        console.log(node.data.target.url)
        return (
          <div
            css={css({
              marginTop: `${space[5]}px`,
              marginBottom: `${space[5]}px`,
              [mediaQueries.md]: {
                marginLeft: `-${space[5]}px`,
                marginRight: `-${space[5]}px`,
              },
            })}
          >
            <BlockVideo url={url} />
          </div>
        )
      }

      if (__typename === `ContentfulBlockGallery`) {
        return (
          <div
            css={css({
              marginTop: `${space[4]}px`,
              marginBottom: `${space[4]}px`,
              [mediaQueries.lg]: {
                marginTop: `${space[5]}px`,
                marginBottom: `${space[5]}px`,
              },
            })}
          >
            <GalleryBlockCarousel images={node.data.target.images} />
          </div>
        )
      }
    }
  },
}