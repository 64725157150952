export const turnPropertiesIntoPropsObject = properties => {
  return properties.reduce((acc, cv) => {
    const key = cv.key
    const value = cv.value

    if (key && value) {
      return { ...acc, [key]: value }
    }

    return acc
  }, {})
}