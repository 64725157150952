import React from 'react'
import { css } from 'styled-components'

import Container from '../../components/Container'

import { fontSizes, space, mediaQueries, colors, breakpoints } from '../../utils/tokens'

import { sectionThemeStyle, text } from '../../utils/styles'
import { TitleLineBreak } from '../TitleLineBreak'
import { LocationsSectionNYC } from './LocationsSectionNYC'
import LocationsSection from './LocationsSection'

export const UIReferenceSection = ({ section }) => {
  if(!section) {
    return null
  }

  const { title, subtitle, component, theme } = section

  return (
    <section
      css={css({
        ...sectionThemeStyle[theme || 'light'],
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px ${space[5]}px`,
        },
      })}
    >
      <Container
        css={css({
          maxWidth: breakpoints.xxl,
        })}
      >
        <div>
          <div>
            {title && (
              <>
                <h2
                  css={css({
                    ...text.sectionHeading,
                  })}
                >
                  {title}
                </h2>
                <TitleLineBreak center />
              </>
            )}
            {subtitle && (
              <p
                css={css({
                  ...text.sectionSubHeading,
                })}
              >
                {subtitle}
              </p>
            )}
          </div>
          <div css={css({ marginTop: `${space[4]}px`})}>{component && <UIReferenceSelector component={component} />}</div>
        </div>
      </Container>
    </section>
  )
}

const UIReferenceSelector = ({ component }) => {
  switch (component) {
    case 'LocationSectionNYC':
      return <LocationsSectionNYC />
    default:
      return null
  }
}